import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LocalStorageKey } from '../../constants/local-storage-key.const';
import { Observable } from 'rxjs';
import { LogObject } from '../../interfaces/logging.interface';
import { LogLevel } from '../../constants/log-type.consts';
@Injectable({
  providedIn: 'root',
})
export class AdiHomeLoggingService {
  constructor(private http: HttpClient) {}

  sendFrontendLogs(logObj: LogObject): Observable<string> {
    const log = {
      eventType: logObj.eventType,
      eventDetail: logObj.eventDetail,
      logLevel: logObj.logLevel,
      applicationName: 'adi-platform',
    };
    return this.logApiCall(log);
  }

  applicationLoadEventLogs(
    eventType: string,
    logLevel: LogLevel,
    eventDetail: string = ''
  ): Observable<string> {
    const logObj = {
      eventType: eventType,
      eventDetail: eventDetail,
      logLevel: logLevel,
      applicationName: 'adi-platform',
    };
    return this.logApiCall(logObj);
  }

  logApiCall(logObj: LogObject) {
    const headers = new HttpHeaders({
      'X-Adi-Trace-Id': localStorage.getItem(LocalStorageKey.TRACE_ID)!,
    });
    const apiEndpoint = `${environment.API_BE_ENDPOINT}/guest/logging`;
    return this.http.post(apiEndpoint, logObj, {
      headers,
      responseType: 'text',
    });
  }
}
